import React from 'react';
import Page from '../src/hoc/Page';
import AppLayout from '../src/containers/AppLayout';
import ContentLayout from '../src/containers/ContentLayout/ContentLayout';
import AuthWrapper from '../src/containers/users/AuthWrapper';
import LandingMain from '../src/containers/LandingMain';
import LandingFooter from '../src/containers/LandingFooter';
import LandingNav from '../src/containers/LandingNav';
import Welcome from '../src/containers/users/Welcome';
import withI18next from '../src/hoc/withI18next';
import { brandName } from '../src/config';

const App = withI18next(['home'])(class extends React.Component {
  componentDidMount() {
    document.title = brandName;
  }

  render() {
    const { t } = this.props;

    return (
      <div className="main-page_index">
        <AppLayout t={t}>
          {brandName != 'Calendoo' &&
            <>
              <LandingNav t={t} logo="/static/imgs/logo-white.png" />
              <LandingMain t={t} />
              <LandingFooter t={t} />
            </>
          }
          {brandName === 'Calendoo' &&
            <ContentLayout hideMenu = {true}>
              <AuthWrapper>
                <Welcome t={t} />
              </AuthWrapper>
            </ContentLayout>
          }
        </AppLayout>
      </div>
    );
  }
});

export default Page(App);
