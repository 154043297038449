/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { PureComponent } from 'react';

import { Router } from '../../../../routes'

import { Space, Button } from 'antd';
import 'antd/lib/space/style/index.css';
import 'antd/lib/button/style/index.css';
import { INNER_ROUTES } from '../../../constants/inner-routes-values';
import { brandName } from '../../../config';

class Welcome extends PureComponent {
  constructor(props) {
    super(props);
  }

  goSignIn() {
    Router.pushRoute(INNER_ROUTES.USERS.SIGN_IN);
  }

  goSignUp() {
    Router.pushRoute(INNER_ROUTES.USERS.SIGN_UP);
  }

  render() {
    let t = this.props.t;

    return (
      <Space direction='vertical' size={'large'}>
        <Space direction='vertical' size={'large'} align={'center'}>
          <p className='font-title color-white' size='24px'>
            {t('auth.startYourBusiness')}
            </p>
            <p className='font-desc color-white'>
            {t('auth.joinITherapeut', { brandName })}
            </p>
        </Space>
        <Button onClick={() => this.goSignUp()} className='btn--auth btn--auth-primary width-100'>
          {t('auth.signUp')}
        </Button>
        <Button onClick={() => this.goSignIn()} className='btn--auth btn--auth-secondary color-white width-100'>
          {t('auth.signIn')}
        </Button>
      </Space>
    );
  }
}

export default Welcome;
