import css from 'styled-jsx/css';

export default css`
.input{
    border: 3px solid red;
}

.auth-wrapper {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: start;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    left: 0;
    top: 0;
    background-color: black;
    z-index: 100;
    padding: 10px 15px;
    box-sizing: border-box;    
    background-image: url('/static/imgs/splash.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 40px;
}

.pass-prefix {
    color: white;
    text-align: center;
    padding: 15px 0 0 0;
    font-size: 14px;
    font-weight: 500;
}

.auth-wrapper > div.logo {
    width: 100%;
    text-align: center;
}

.auth-wrapper > div.logo img {
    max-width: 260px;
}

.auth-wrapper > div:last-child {
    background-color: transparent;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;        
    max-width: 815px;
}

.auth-wrapper > div {
    position: relative;
    animation-name: auth-animation;
    animation-duration: .5s;
    animation-timing-function: linear;        
}

.auth-wrapper > div > div {
    display: flex;
    justify-content: center;
}
`;
