/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { PureComponent } from 'react';
import style from './styles';
import { logoWhiteUrl } from '../../../config';

class AuthWrapper extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className = 'auth-wrapper'>
        <div className = 'logo'>
          <img src={logoWhiteUrl} />
        </div>
        <div>
          {this.props.children}
        </div>
        <style jsx>{style}</style>
      </div>
    );
  }
}

export default AuthWrapper;
